$( document ).ready(function() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });
     
    $(".ajax-form").on("submit", function(e){
        e.preventDefault();
        thisform=$(this);
        if(thisform.find(".form_msg_error").length>0) thisform.find(".form_msg_error").html("").hide();
        thisform.find(".form_msg_error").html("").fadeOut("slow");
        $.ajax({
            type:'POST',
            url:$(this).attr("action"),
            data: $(this).serialize(),
            success:function(data){
                console.log(data);
                if(thisform.find(".form_msg_success").length>0){
                    thisform.find(".form_msg_success").html(data.Msg).fadeIn("slow");
                    thisform.find(":submit").attr("disabled");
                }
                thisform.find(":submit").attr("disabled");
            },
            error: function(error){
                console.error(error.responseJSON.MsgError);
                if(thisform.find(".form_msg_error").length>0){
                    thisform.find(".form_msg_error").html(error.responseJSON.MsgError).fadeIn("slow");
                }else{
                    $("#header_error").html(error.responseJSON.MsgError).slideDown("slow").delay(1500).slideUp("slow");
                }
                
            }
        });
    
    });

    $(".ajax-form-login").on("submit", function(e){
        e.preventDefault();
        var url_redirect=$(this).find("#url_redirect").val();
        thisform=$(this);
        if(thisform.find(".form_msg_error").length>0) thisform.find(".form_msg_error").html("").hide();
        $.ajax({
            type:'POST',
            url:$(this).attr("action"),
            data: $(this).serialize(),
            success:function(data){
                if(url_redirect!="" && url_redirect!=undefined){
                    location.href=url_redirect;
                }else{
                    location.reload();
                }
                
            },
            error: function(error){
                console.error(error.responseJSON.MsgError);
                if(thisform.find(".form_msg_error").length>0){
                    thisform.find(".form_msg_error").html(error.responseJSON.MsgError).fadeIn("slow");
                }else{
                    $("#header_error").html(error.responseJSON.MsgError).slideDown("slow").delay(1500).slideUp("slow");
                }
                
            }
        });
    
    });

    $(".ajax-logout-session").on("click", function(e){
        e.preventDefault();
        $.ajax({
            type:'POST',
            url:$(this).attr("action"),
            data: $(this).serialize(),
            success:function(data){
                location.reload();
            },
            error: function(error){
                console.error(error.responseJSON.MsgError);
                $("#header_error").html(error.responseJSON.MsgError).slideDown("slow").delay(1500).slideUp("slow");
            }
        });
    
    });
    
    if($("form input.pais_gsoft").length>0){
        alert("Hola");
    }
       
});
