$( document ).ready(function() {
    $("#login").click(function(){
        if(!$(".bloque_login").hasClass("show")){
            $(".bloque_login").animate({
                top:0
            },200).addClass("show");
        }
    });
    $("#cancel_login").click(function(){
        if($(".bloque_login").hasClass("show")){
            $(".bloque_login").animate({
                top:-40
            },200).removeClass("show");
        }
    });
    
});

